@import '../PageError';

.http-error {
  &__status-code {
    font-size: calc(1em + 10vmin);
    font-weight: bold;
    margin: 0;
  }

  &__description {
    text-align: center;
  }
}
