@import '../../styles/colours';

.spinner {
  display: inline-block;
  border: 0.2em solid $highlight;
  border-top: 0.2em solid $dark-foreground;
  border-bottom: 0.2em solid $dark-foreground;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
