.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  
  &__text {
    margin: 0 0 0.5em 0.5em
  }
}
