@import '../../../styles/colours';

.footer {
  align-items: flex-end;
  border-top: 2px solid $highlight;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: max(calc(2rem/3), 12px);
  justify-content: space-between;
  padding: 1rem;

  p {
    display: inline-block;
    margin: 0;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  &__address {
    display: inline;
    font-style: initial;
  }

  &__ltd {
    p {
      clear: both;
      float: left;
    }

    address {
      clear: both;
      float: left;
      font-style: initial;
      margin-top: 1em;
    }
  }
}