.print-hidden {
  @media only print {
    display: none;
  }
}

.site {
  background-color: transparent;
  color: white;

  margin: 0 auto;

  max-width: 1280px;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  &__header {
    flex: none;
    @extend .print-hidden;
  }

  &__main {
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;

    padding: 1rem;
  }

  &__footer {
    flex: none;
    @extend .print-hidden;
  }

  &__loading {
    flex: 1 0 auto;
  }
}
