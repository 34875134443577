@import '../../../styles/colours';

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 2px solid $highlight;
  font-size: 2rem;

  a {
    color: $dark-foreground;
    text-decoration: none;
  }

  &__hero {
    margin-right: 1rem;
  }

  &__title {
    font-size: 1em;
    margin: 0;
  }

  &__navigation {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 1rem 0 0;
    }

    li {
      display: inline;
      font-size: 0.5em;
      
      a {
        border-color: transparent;
        transition: border-bottom 0.2s ease-in;
      }

      a:hover {
        border-bottom: 2px solid $highlight;
      }

      &:not(:last-child) {
        margin-right: 0.5em;
        
        &:after {
          content: '|';
          color: $highlight;
          margin-left: 0.5em;
        }
      }
      
    }
  }
}