@import './styles/main';

$minFontSize: 16px;
$maxFontSize: 22px;
$minWidth: 320px;
$maxWidth: 1280px;

html {
  @include fluid-type($minWidth, $maxWidth, $minFontSize, $maxFontSize);
  overflow-y: scroll;
}

a {
  color: $dark-foreground;
  text-decoration-color: $highlight;
}

.wrapper {
  width: 100%;
  background-color: $dark-background;
}