.social-icons {
  display: flex;
  flex-direction: row;

  &__link {
    margin: 0 calc(1em/4);
    transition: color .2s ease-in;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      &.linkedin {
        color: #0a66c2;
      }
      &.mastodon {
        color: #6364ff;
      }
      &.threads {
        color: #fcaf45;
      }
    }
  }
}